import React from "react";
import { openChatWindow } from "./src/helpers/open-chat";

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    console.log(`# IntersectionObserver is polyfilled!`);
    return import(`intersection-observer`);
  }
};

export const wrapPageElement = ({ element }) => {
  return <>{element}</>;
};

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];
  if (window.locations[window.locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href);
  }
  window.previousPath = window.locations[window.locations.length - 2];

  if (window?.location?.href && window?.location?.href.includes("exclude/webchat-redirect")) {
    if (window.previousPath) {
      openChatWindow(window.previousPath);
    }
  }
};
