export const openChatWindow = (originUrl) => {
  if (originUrl.includes("#")) {
    originUrl = originUrl.split("#")[0];
  }

  if (originUrl.includes("?")) {
    originUrl = originUrl.split("?")[0];
  }
  window.location.assign(`${originUrl}?chatOpen=true`);
};

export const isWithinChatOpeningHours = () => {
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const d = new Date();
  let day = weekdays[d.getDay()];
  let time = d.getUTCHours();

  const weekdayClosedHours = time < 9 || time > 21;
  const weekendClosedHours = (day === "Saturday" || day === "Sunday") && (time < 10 || time > 18);

  if (weekdayClosedHours || weekendClosedHours) {
    return false;
  }

  return true;
};

export const overwriteChatRedirect = () => {
  setTimeout(() => {
    document.querySelectorAll("a").forEach((ele) => {
      if (ele.href.includes("exclude/webchat-redirect")) {
        ele.setAttribute("onclick", "window.genesysOpen()");
        ele.href = "#";
      }
    });
  }, 1);
};
